<template>
  <div class="realize_wrap">
    <div class="realize_img">
      <div class="img_tit">
        成就客户，成就伙伴
      </div>
      <div class="img_tit">
       打造领先的智慧园区生态
      </div>
    </div>

    <div class="show_box_index" >
      
      <div class="production_title_wrap">
        <div class="production_inner">
          <div class="inner_left">
            <img src="../../assets/img/tupianright.png" alt="" />
          </div>
          <div class="inner_center">
            <div class="production_title">上海易曙软件有限公司</div>
          </div>
          <div class="inner_right">
            <img src="../../assets/img/tupianleft.png" alt="" />
          </div>
        </div>
      </div>
      <div class="advantage_out_wrap flexLayout_column" >
        <div class="table_wrap hidden-xs-only" style="margin-top: 21px;;width: 64%;    text-align: center;">
          <p style=" font-size: 1.4rem">成立于2017年7月,基于人工智能、大数据、物联网、数字孪生等前沿技术，专注于智慧园区、智慧城市和BIM技术的研发与应用</p>
        </div>
        <div class="table_wrap_mobile hidden-sm-and-up" style="margin-top: 14px;font-size: 14px;">
          <p>
               成立于2017年7月，基于人工智能、大数据、物联网、数字孪生等前沿技术，专注于智慧园区、智慧城市和BIM技术的研发与应用。
          </p>
        
        </div>

        <div class="china_map">
        <img src="../../assets/img/coor1.png" alt="" />
      </div>
        <el-row class="advantage_wrap" style="margin-top: 14px;">
          <el-col class="advantage_item"
            v-for="(item, index) in advantageList"
            :key="index"
            :span="7"
            :xs="11">
            <div class="item_top" style="position: relative;">
              <div class="tit">{{ item.title }}</div>
              <div class="icon hidden-xs-only" style="font-size: 49px;position: absolute;right: 0;">
               {{item.icon}}
              </div>
            </div>
            <ul
              class="item_desc"
              v-for="(desc, index2) in item.desc"
              :key="index2"
            >
              <li>{{ desc }}</li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <!-- <div class="production_title_wrap">
        <div class="production_inner">
          <div class="inner_left">
            <img src="../../assets/img/tupianright.png" alt="" />
          </div>
          <div class="inner_center">
            <div class="production_title">ESOO合伙人</div>
          </div>
          <div class="inner_right">
            <img src="../../assets/img/tupianleft.png" alt="" />
          </div>
        </div>
      </div> -->
      <!-- <PersonCard /> -->
      <!-- <div class="production_title_wrap">
        <div class="production_inner">
          <div class="inner_left">
            <img src="../../assets/img/tupianright.png" alt="" />
          </div>
          <div class="inner_center" style="width: 50%">
            <div class="production_title">合作分布</div>
            <div class="production_desc">
              已实现核心园区70%覆盖和数百万医药企业的精准覆盖
            </div>
          </div>
          <div class="inner_right">
            <img src="../../assets/img/tupianleft.png" alt="" />
          </div>
        </div>
      </div> -->
    
    </div>
    <!-- <div class="show_box_index" v-if="tabSelectIndex === 1">
      <div class="timeline_wrap">
        <TimeLineYunYi/>
        <TimeLineMobile/>
      </div>
    </div>
    <div class="show_box_index" v-if="tabSelectIndex === 2">
      <div class="three_box">
        <h1 style="font-size:1rem;">商誉资质</h1>
      </div>
      <div class="three_box_card">
        <el-row type="flex" justify="center" class="out_card" :gutter="10">
          <el-col
            :span="7"
            :xs="11"
            v-for="(item, index) in certificateList"
            :key="index"
            class="card_item"
          >
            <el-card :body-style="{ padding: '0px' }">
              <img
                :src="item.img"
                class="image"
              />
              <div style="padding: 14px;min-height:60px;">
                <span style="font-size:0.8rem;">{{item.desc}}</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div> -->
    <div class="show_box_index" >
  
      <div class="production_title_wrap">
        <div class="production_inner">
          <div class="inner_left">
            <img src="../../assets/img/tupianright.png" alt="" />
          </div>
          <div class="inner_center">
            <div class="production_title">联系我们</div>
          </div>
          <div class="inner_right">
            <img src="../../assets/img/tupianleft.png" alt="" />
          </div>
        </div>
      </div>
      <div class="four_card_spa hidden-xs-only">
        <el-row type="flex" justify="space-betw`een" class="out_card" style="    justify-content: space-around;">
          <el-col :span="7" class="card_item" style="text-align: center;">
            <h1>黄会发</h1>
            <p>商务经理</p>
            <h1>13764460678</h1>
          </el-col>
          <el-col :span="7" class="card_item" style="text-align: center;border-right: 1px solid #6b60d170;border-left: 1px solid #6b60d170">
      
            <img style="width: 135px;margin: 0; border-radius: 7px;" src="../../assets/dicaChat.jpg" alt="" />

     
          </el-col>
          <el-col :span="7" class="card_item" style="text-align: center;">
            <h1>桂成仁</h1>
            <p>大客户经理</p>
            <h1>17701753702</h1>
          </el-col>
        </el-row>
      </div>
      <div class="real_wrap">
        <ReailzeCard :reailzeList="reailzeList" class="hidden-sm-and-up" />
      </div>
    </div>
    <CallMe />
    <MobileCallme></MobileCallme>
  </div>
</template>

<script>
// import PersonCard from './PersonCard.vue'
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
import ReailzeCard from './ReailzeCard.vue'
import TimeLineYunYi from './TimeLineYunYi.vue'
import TimeLineMobile from './TimeLineMobile.vue'
export default {
  data() {
    return {
      tabList: [
        {
          tit: 'ESOO介绍',
          name: '1',
        },
        // {
        //   tit: '发展历程',
        //   name: '2',
        // },
        // {
        //   tit: '荣誉资质',
        //   name: '3',
        // },
        {
          tit: '联系ESOO',
          name: '4',
        },
      ],
      tabSelectIndex: 0,
      advantageList: [
        {
          icon: '🏆',
          title: '自主知识产权',
          desc: ['技术实力雄厚', '拥有40多项自主知识产权，技术团队占比超过50%'],
        },
        {
          icon: '🏅',
          title: '高新技术企业',
          desc: ['企业荣誉', '通过国家高新技术企业认证，具备强大的研发和创新能力'],
        },
        {
          icon: '💎',
          title: '行业影响力',
          desc: [
            '智慧园区软件产品证书',
            '拥有智慧园区相关软件产品的权威认证，确保产品的专业性和市场认可度',
          ],
        },
        {
          icon: '💡',
          title: '同济大学研究中心',
          desc: [
            '交通BIM研究中心唯一企业成员',
            '依托顶尖科研资源，推动BIM技术在智慧园区中的应用',
          ],
        },
        {
          icon: '🔬',
          title: '中科院研究所',
          desc: ['中科院自动化研究所提供强力技术支持', '确保产品在智能化和自动化领域的领先性'],
        },
        {
          icon: '📈',
          title: '客户口碑',
          desc: [
            '服务300+园区',
            '客户满意度达95%以上。获得客户的高度评价和长期合作',
          ],
        },
      ],
      timelineEvent: [
        {
          event:
            '《中国新闻报》点赞Esooft产业大脑的数字化实践，并写入两会专刊',
          time: '2021/11/24',
          img: require('../../assets/img/new7.png'),
        },
        {
          event:
            '《中国新闻报》点赞Esooft产业大脑的数字化实践，并写入两会专刊',
          time: '2021/11/24',
          img: require('../../assets/img/new7.png'),
        },
        {
          event:
            '《中国新闻报》点赞Esooft产业大脑的数字化实践，并写入两会专刊',
          time: '2021/11/24',
          img: require('../../assets/img/new7.png'),
        },
        {
          event:
            '《中国新闻报》点赞Esooft产业大脑的数字化实践，并写入两会专刊',
          time: '2021/11/24',
          img: require('../../assets/img/new7.png'),
        },
        {
          event:
            '《中国新闻报》点赞Esooft产业大脑的数字化实践，并写入两会专刊',
          time: '2021/11/24',
          img: require('../../assets/img/new7.png'),
        },
        {
          event:
            '《中国新闻报》点赞Esooft产业大脑的数字化实践，并写入两会专刊',
          time: '2021/11/24',
          img: require('../../assets/img/new7.png'),
        },
        {
          event:
            '《中国新闻报》点赞Esooft产业大脑的数字化实践，并写入两会专刊',
          time: '2021/11/24',
          img: require('../../assets/img/new7.png'),
        },
        {
          event:
            '《中国新闻报》点赞Esooft产业大脑的数字化实践，并写入两会专刊',
          time: '2021/11/24',
          img: require('../../assets/img/new7.png'),
        },
      ],
      contactMsg: [
        {
          tit: '市场合作',
          desc: 'Business@hsmap.com',
          icon: require('../../assets/img/61.png'),
        },
        {
          tit: '售前咨询',
          desc: '000-000-0000',
          icon: require('../../assets/img/60.png'),
        },
        {
          tit: '售后咨询',
          desc: 'Product@hsmap.com',
          icon: require('../../assets/img/61.png'),
        },
      ],
      reailzeList: [
        {
          tit: '大客户经理',
        },
      ],
      certificateList:[
        {
          img:'https://hs-official-site-prod.oss-cn-hangzhou.aliyuncs.com/client/official/20210629/kWXEiepyNp1624936457266.png',
          desc:'荣获“2021年杭州市准独角兽企业”荣誉称号'
        },
        {
          img:'https://hs-official-site-prod.oss-cn-hangzhou.aliyuncs.com/client/official/20210318/mkGhTrdJTi1616038179236.jpg',
          desc:'2020年Esooft成为华为认证级ISV伙伴'
        },
        {
          img:'https://hs-official-site-prod.oss-cn-hangzhou.aliyuncs.com/client/official/20210318/SS4ncBsjQs1616038188320.jpg',
          desc:'Esooft董事长金霞入选“2020年度女性创业者TOP10'
        },
        {
          img:'https://hs-official-site-prod.oss-cn-hangzhou.aliyuncs.com/client/official/20210318/DZPsTTfkJi1616038196212.jpg',
          desc:'2020年Esooft荣获“浙江省高新技术企业”荣誉称号'
        },
        {
          img:'https://hs-official-site-prod.oss-cn-hangzhou.aliyuncs.com/client/official/20210318/kjfX3JRw641616038294809.jpg',
          desc:'荣获“2020中国健康新势力企业”荣誉称号'
        },
        {
          img:'https://hs-official-site-prod.oss-cn-hangzhou.aliyuncs.com/client/official/20210318/Wr3JPJ5wsG1616038303821.jpg',
          desc:'Esooft董事长金霞入选科技部“2019年创新人才推进计划'
        },
        {
          img:'http://hs-official-site-test.oss-cn-hangzhou.aliyuncs.com/client/official/20200604/b2TNNdNsCe1591254359959.png',
          desc:'荣获“2019年浙江省创新企业百强”称号'
        },
        {
          img:'http://hs-official-site-test.oss-cn-hangzhou.aliyuncs.com/client/official/20200604/8hXzwptN261591254324083.png',
          desc:'2019年获得"中国科技创新领军企业"称号'
        },
        {
          img:'http://hs-official-site-test.oss-cn-hangzhou.aliyuncs.com/client/official/20200604/DcbXekjH2W1591254266381.png',
          desc:'ESOO创始人杨红飞荣获"2019年中国B2B领域卓越创业家"荣誉称号'
        },
      ]
    }
  },
  methods: {
    changeTabIndex(index) {
      this.tabSelectIndex = index
    },
  },
  components: {
    // PersonCard,
    CallMe,
    MobileCallme,
    ReailzeCard,
    TimeLineYunYi,
    TimeLineMobile
  },
  created(){
    this.tabSelectIndex = this.$route.path.substr(this.$route.path.length - 1) * 1 - 1
  },
  watch: {
    $route() {
      this.tabSelectIndex =
        this.$route.path.substr(this.$route.path.length - 1) * 1 - 1
    },
  },
}
</script>

<style lang="scss" scoped>
.flexLayout_column{
  display: flex;
    flex-direction: column;
    align-items: center;
}
.realize_wrap {
  .realize_img {
    width: 100%;
    height: 600px;
    @media screen and (max-width: 768px) {
      height: 50vw;
    }
    background: url('../../assets/img/aboutbg.054cc8b.jpg') 0 0 no-repeat;
    background-size: 100% 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .img_tit {
      font-size: 60px;
      font-weight: bold;
      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
    .img_desc {
      font-size: 32px;
      margin-top: 20px;
      @media screen and (max-width: 768px) {
        font-size: 0.85rem;
      }
    }
    .img_btn {
      width: 180px;
      height: 50px;
      border: solid 1px rgb(255, 166, 0);
      background: rgba(25, 166, 0, 0.1);
      color: orange;
      position: absolute;
      bottom: 15%;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        color: #fff;
        background: orange;
      }
      @media screen and (max-width: 768px) {
        width: 80px;
        height: 30px;
        font-size: 14px;
      }
    }
  }
  .realize_nav_wrap {
    width: 100%;
    height: 60px;
    @media screen and (max-width: 768px) {
      height: 50px;
    }
    .realize_nav {
      height: 100%;
      display: flex;
      align-items: center;
      .active {
        font-weight: bold;
        color: #000;
      }
      .realize_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .nav_top {
          font-size: 18px;
          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }
        .nav_bottom {
          width: 30%;
          height: 4px;
          margin-top: 10px;
          background: #2091d3;
        }
      }
    }
  }
  .product_shows_wrap {
    width: 100%;
    background-size: cover;
    padding: 10px 0 50px 0;
    margin-top: -7px;
    box-sizing: border-box;
    height: 700px;
    position: relative;
    @media screen and (max-width: 768px) {
      background: #f4f9fc;
      height: auto;
    }
    .table_wrap {
      position: absolute;
      top: 23%;
      left: 20%;
      width: 400px;
      .table_form {
        .table_form_item {
          font-weight: bold;
        }
      }
    }
    .table_wrap_mobile {
      padding: 1rem;
      p {
        font-size: 0.9rem;
      }
      .table_form {
        .table_form_item {
          font-weight: bold;
          margin-top: 1rem;
          font-size: 0.85rem;
          span {
            color: #000;
            font-size: 0.9rem;
            padding-right: 30px;
          }
        }
      }
    }
  }
  .advantage_out_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    .advantage_wrap {
      width: 70%;
      display: flex;
      margin-top: 70px;
      justify-content: center;
      flex-wrap: wrap;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .advantage_item {
        height: auto;
        padding: 30px 20px 60px 20px;
        margin-right: 10px;
  
        margin: 15px;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
        @media screen and (max-width: 768px) {
          margin: 0;
          padding: 0 15px;
          margin-top: 3px;
          height: 140px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          height: 200px;
        }
        .item_top {
          display: flex;
          align-items: center;
          @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
          .icon {
            i {
              color: #2091d3;
              font-size: 26px;
              padding-right: 10px;
              @media screen and (max-width: 768px) {
                font-size: 2rem;
                margin-bottom: 10px;
              }
            }
          }
          .tit {
            width: 100%;
            color: #1c1c1c;
            font-size: 28px;
            font-weight: bold;
            @media screen and (max-width: 768px) {
              font-size: 1rem;
              text-align: left;
            }
          }
        }
        .item_desc {
          font-size: 20px;
          line-height: 20px;
          margin: 0;
          padding: 0;
          margin-top: 20px;
          color: #646464;
          list-style: none;
          li {
            line-height: 2rem;
            @media screen and (max-width: 768px) {
              line-height: 1rem;
              font-size: 12px;
            }
            &:first-child {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
  .china_map {
    width: 70%;
    margin: 50px auto;
    img {
      width: 100%;
    }
  }
  .timeline_wrap {
    width: 70%;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .timeline_card {
      h4 {
        font-size: 24px;
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }
      .timeline_time{
        font-size: 12PX;
      }
      img {
        width: 30%;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  .three_box {
    width: 70%;
    margin: 0 auto;
    height: 200px;
    text-align: center;
    background: url('../../assets/img/ryzz.bc140e4.jpg') 0 0 no-repeat;
    background-size: cover;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    h1 {
      font-size: 40px;
      line-height: 200px;
      color: #fff;
    }
  }
  .three_box_card {
    width: 70%;
    margin: 30px auto;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .out_card {
      flex-wrap: wrap;
      .card_item {
        @media screen and (max-width: 768px) {
          margin-top: 10px;
        }
        &:nth-child(n + 4) {
          margin-top: 10px;
        }
        img {
          padding-top: 30px;
          width: 100%;
        }
      }
    }
  }
  .four_wrap {
    width: 70%;
    margin: 0 auto;
    background: url('../../assets/img/58.e28b1c0.png') 0 0 no-repeat;
    background-size: cover;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 150px;
    }
    .out_card {
      width: 100%;
      flex-wrap: wrap;
      .card_item {
        &:nth-child(n + 4) {
          margin-top: 10px;
        }
        .card_content {
          display: flex;
          flex-direction: column;
          text-align: center;
          width: 100%;
          img {
            padding-top: 30px;
            width: 30%;
            @media screen and (max-width: 768px) {
              width: 50%;
            }
          }
        }
      }
    }
  }
  .four_card_spa {
    width: 70%;
    margin: 30px auto;
    .out_card {
      .card_item {
        h1 {
          font-size: 30px;
          margin-top: 10px;
        }
        img {
          margin-top: 30px;
          width: 5%;
        }
        p {
          font-size: 21px;
          margin-top: 10px;
        }
        .card_bg {
          width: 5px;
          height: 30px;
          background: #13519f;
        }
      }
    }
  }
}
.production_title_wrap {
  width: 100%;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
  .production_inner {
    display: flex;
    justify-content: center;
    .inner_left {
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media screen and (max-width: 768px) {
        flex: 1;
      }
      img {
        width: 30%;
      }
    }
    .inner_center {
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 768px) {
        flex: 2.2;
      }
      .production_title {
        font-size: 40px;
        font-weight: bold;
        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }
      .production_desc {
        font-size: 16px;
        color: #787878;
        margin-top: 10px;
        @media screen and (max-width: 768px) {
          margin-top: 10px;
          font-size: 14px;
        }
      }
    }
    .inner_right {
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media screen and (max-width: 768px) {
        flex: 1;
      }
      img {
        width: 30%;
      }
    }
  }
}
</style>