var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"realize_wrap"},[_vm._m(0),_c('div',{staticClass:"show_box_index"},[_vm._m(1),_c('div',{staticClass:"advantage_out_wrap flexLayout_column"},[_vm._m(2),_vm._m(3),_vm._m(4),_c('el-row',{staticClass:"advantage_wrap",staticStyle:{"margin-top":"14px"}},_vm._l((_vm.advantageList),function(item,index){return _c('el-col',{key:index,staticClass:"advantage_item",attrs:{"span":7,"xs":11}},[_c('div',{staticClass:"item_top",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"tit"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"icon hidden-xs-only",staticStyle:{"font-size":"49px","position":"absolute","right":"0"}},[_vm._v(" "+_vm._s(item.icon)+" ")])]),_vm._l((item.desc),function(desc,index2){return _c('ul',{key:index2,staticClass:"item_desc"},[_c('li',[_vm._v(_vm._s(desc))])])})],2)}),1)],1)]),_c('div',{staticClass:"show_box_index"},[_vm._m(5),_c('div',{staticClass:"four_card_spa hidden-xs-only"},[_c('el-row',{staticClass:"out_card",staticStyle:{"justify-content":"space-around"},attrs:{"type":"flex","justify":"space-betw`een"}},[_c('el-col',{staticClass:"card_item",staticStyle:{"text-align":"center"},attrs:{"span":7}},[_c('h1',[_vm._v("黄会发")]),_c('p',[_vm._v("商务经理")]),_c('h1',[_vm._v("13764460678")])]),_c('el-col',{staticClass:"card_item",staticStyle:{"text-align":"center","border-right":"1px solid #6b60d170","border-left":"1px solid #6b60d170"},attrs:{"span":7}},[_c('img',{staticStyle:{"width":"135px","margin":"0","border-radius":"7px"},attrs:{"src":require("../../assets/dicaChat.jpg"),"alt":""}})]),_c('el-col',{staticClass:"card_item",staticStyle:{"text-align":"center"},attrs:{"span":7}},[_c('h1',[_vm._v("桂成仁")]),_c('p',[_vm._v("大客户经理")]),_c('h1',[_vm._v("17701753702")])])],1)],1),_c('div',{staticClass:"real_wrap"},[_c('ReailzeCard',{staticClass:"hidden-sm-and-up",attrs:{"reailzeList":_vm.reailzeList}})],1)]),_c('CallMe'),_c('MobileCallme')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"realize_img"},[_c('div',{staticClass:"img_tit"},[_vm._v(" 成就客户，成就伙伴 ")]),_c('div',{staticClass:"img_tit"},[_vm._v(" 打造领先的智慧园区生态 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"production_title_wrap"},[_c('div',{staticClass:"production_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center"},[_c('div',{staticClass:"production_title"},[_vm._v("上海易曙软件有限公司")])]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_wrap hidden-xs-only",staticStyle:{"margin-top":"21px","width":"64%","text-align":"center"}},[_c('p',{staticStyle:{"font-size":"1.4rem"}},[_vm._v("成立于2017年7月,基于人工智能、大数据、物联网、数字孪生等前沿技术，专注于智慧园区、智慧城市和BIM技术的研发与应用")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table_wrap_mobile hidden-sm-and-up",staticStyle:{"margin-top":"14px","font-size":"14px"}},[_c('p',[_vm._v(" 成立于2017年7月，基于人工智能、大数据、物联网、数字孪生等前沿技术，专注于智慧园区、智慧城市和BIM技术的研发与应用。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"china_map"},[_c('img',{attrs:{"src":require("../../assets/img/coor1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"production_title_wrap"},[_c('div',{staticClass:"production_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center"},[_c('div',{staticClass:"production_title"},[_vm._v("联系我们")])]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }